<template>
  <section class="user-profile">
    <br>
    <md-card class="md-layout-item md-size-50 md-small-size-100">
      <md-card-header>
        <div class="md-title">Current Billing</div>
      </md-card-header>
      <md-card-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label :for="$id('last-date')">Last Payment Date</label>
              <md-input disabled :id="$id('last-date')" type="text"
                        :value="lastPaymentDate.split('T')[0] || 'N/A'">
              </md-input>
            </md-field>
            <md-field>
              <label :for="$id('new-date')">Next Payment Date</label>
              <md-input disabled
                        :id="$id('new-date')"
                        type="text"
                        :value="nextPaymentDate || 'N/A'">
              </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-button class="md-raised md-primary" @click="goSupport">Renew Now</md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UserBilling',
  computed: {
    ...mapGetters(['lastPaymentDate']),
    nextPaymentDate() {
      if (this.lastPaymentDate) {
        const lastDate = new Date(this.lastPaymentDate);
        const addMonth = lastDate.setMonth(lastDate.getMonth() + 1);
        const dt = new Date(addMonth).toISOString();
        return dt.split('T')[0];
      }
      return 'N/A';
    },
  },
  methods: {
    goSupport() {
      this.$router.push({ name: 'SupportView', params: { type: '2' } });
    },
  },
};
</script>
